import { captureException } from "@sentry/nextjs"
import { has } from "lodash"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IModel, UserRole } from "@api/schema"
import ErrorPage from "@components/ErrorPage"
import SpinnerPage from "@components/SpinnerPage"
import { platformIsInDevEnvironment } from "@definitions/platform"
import { loadModelAction } from "@redux/helper/actions"
import { IRequestState } from "@redux/helper/state"
import { AppState } from "@redux/reducer"
import { selectById, selectSingleEntityUsecaseState } from "@redux/reducer/data"
import { DetailResultEntityTypes, LoadableEntityType } from "@redux/reduxTypes"
import { useDynamicTranslation } from "@services/i18n"

/**
 * return type of the useEntity hook
 */
export type UseEntityResult<Type extends IModel> = {
  entity: Type
  id: number
  request: IRequestState
  errorOrSpinnerPage: JSX.Element
}

/**
 * options to define details of the work mode of the useEntity hook
 */
type UseEntityOptions = {
  /**
   * If true, the hook does not dispatch API calls. Default: the hooks dispatches API calls.
   * Used to avoid unnecessary API calls, e.g. if the id is not given, because
   * needed data has not yet fetched from the API or a MODULE is off etc.
   *
   * Usage example:
   * useEntity<IChallenge>(
   * EntityType.Challenge,
   * id,
   * // avoids loading when module is off or id is not available (yet)
   * { doNotLoad: !MODULE_CHALLENGE_AVAILABLE || !id }
   * )
   */
  doNotLoad?: boolean
  /**
   * Role that must be used when fetching the entities, e.g. to differentiate manager calls from user calls.
   *
   * NOTE: If the coder defines a usedRole in a component/page, that also is available for users
   * that do not have this role, the hook does not return an entity but a loadedWithWrongPrivilege error.
   */
  usedRole?: UserRole
}

/**
 * Hook for providing an entity by its EntityType and id:
 * get it from a state if it is available or load it from the backend, but make sure it comes from a single call
 * to provide full entity data.
 *
 * Because useEntity loads the entity if there is no single entity request state for the entity
 * it is ensured, that the entity is loaded with detailResult and is not used from an earlier collection call.
 * NOTE: this expects, that the API NEVER returns a single entity call with detailResult === false, but always
 * returns the entity with detailResult === true or without this property. @see https://futureprojects.atlassian.net/browse/FCP-1505
 *
 * @todo https://futureprojects.atlassian.net/browse/FCP-1380
 */
export const useEntity = <Type extends IModel>(
  entityType: LoadableEntityType,
  id: number,
  options?: UseEntityOptions
): UseEntityResult<Type> => {
  const dispatch = useDispatch()
  const t = useDynamicTranslation()

  // get the (possibly loaded) entity from the state
  const entity = useSelector((state: AppState) => selectById<Type>(state, entityType, id))


  // DEV-Test: make sure, real incoming data has matching representation:
  // incoming detailResult entity is part of DetailResultEntityTypes
  if (platformIsInDevEnvironment() && has(entity, "detailResult") && !DetailResultEntityTypes.includes(entityType)) {
    const err = new Error(`EntityType ${entityType} is not in DetailResultEntityTypes but an incoming entity of this type has property detailResult`)
    captureException(err)
  }

  // Use the request of a possible loaded entity from the selectSingleEntityUsecaseState
  // and check the request state in the useEffect, to make sure
  // it is loaded as single request to make sure there are detailed results
  // and not only a data stub from a collection call.
  const request = useSelector((state: AppState) => id ? selectSingleEntityUsecaseState(state, entityType, id.toString()) : null)

  const dispatchLoadEntity = () => dispatch(loadModelAction(entityType, id, id.toString(), options?.usedRole))

  // one useEffect per scenario and to be more readable
  useEffect(() => {
    // trigger the loading
    // if there is an id but no entity
    // if loading has not happened yet
    // if loading is not already running
    if (id
      // @todo: Problem ist: wenn das entity (s.o.) durch selectByIdWithRole geholt wird, kann es sein, dass es nicht da ist,
      // weil es mit dieser Rolle noch nicht abgerufen wurde.
      // Es kann aber zugleich sein, dass bereits ein Request existiert, weil es (mit dieser ID) bereits in einer anderen Rolle
      // abgerufen wurde. Dann sagen Entity und Request etwas gegenteiliges: Entity: noch nicht vorhanden, Request: vorhanden!
      // Die Prüfung auf request.loaded geht daher fehl, weil es ja geladen werden muss, OBWOHL schon ein Request dafür vorliegt.
      // Die Prüfung auf loadingError kann daher z.B. fehlführen, weil ein Error aufgekommen sein könnte beim Abruf als
      // normaler User, aber nun ein Call getriggert werden soll als Manager.
      // Da muss diese Prüfung eigentlich raus...
      // Müssen wir die Requests erweitern? Nicht nur die ID speichern, sondern ID in Kombination mit UserRole?
      // <- @todo gilt das noch als Fragestellung (Stand: 07.04.2024) nach dem Merge eines useEntity-Branches?
      && (!entity || (!request || request.loaded === false))
      && !(request?.isLoading || request?.loadingError)
      && !options?.doNotLoad
    ) {
      dispatchLoadEntity()
    }
  }, [
    id,
    JSON.stringify(request),
    // Trigger useEffect as soon as the options change, b/c the mode could change from
    // "do not load" to "load now" -> independed of criteria change, e.g. when the opening of a card should trigger the loading
    JSON.stringify(options)
  ])

  useEffect(() => {
    // This hook is used to return an entity that is fetched by a single API fetch.
    // Therefor it must be distinguished from entities that have been fetched by collection calls
    // when those collection calls return a crippled version of the entity. Those entities
    // are recognized by having the detailResult prop and its value is false.
    // If an entity exists in its crippled version it is reloaded here.
    // Testing on property (instead of membership in DetailResultEntityTypes) is resilient to changes on backend.
    if (!options?.doNotLoad && entity && has(entity, "detailResult") && entity.detailResult === false) {
      dispatchLoadEntity()
    }
  }, [entity?.detailResult, JSON.stringify(options)])

  useEffect(() => {
    // The backend sometimes delivers entities without "usedRoles", e.g. a FeedbackInvitation
    // so we assume, that entities without the usedRoles property are always delivered with the full data,
    // whatever role fetches it.
    // In this case, when the entity from the state does not have this prop, the entity has to be fetched again with the usedRole.
    // Otherwise: if the usedRole failed to be used, no entity is returned.
    if (!options?.doNotLoad && options?.usedRole && entity?.usedRoles && !entity?.usedRoles.includes(options?.usedRole)) {
      dispatchLoadEntity()
    }

  }, [options?.usedRole, entity?.usedRoles, JSON.stringify(options)])

  let errorOrSpinnerPage: JSX.Element = null
  if (!id) {
    errorOrSpinnerPage = <ErrorPage statusCode={404} error={"failure.object.notFound"} />
  } else if (request?.loadingError) {
    errorOrSpinnerPage = <ErrorPage statusCode={404} error={request?.loadingError} />
  } else if (request?.loaded && !entity) {
    errorOrSpinnerPage = <ErrorPage statusCode={404} error={t("error", "failure.object.notFound")} />
  } else if (request?.isLoading) {
    errorOrSpinnerPage = <SpinnerPage title={t("common", "page.loading.title")} description={t("common", "page.loading.title")} />
  }

  return { id, entity, request, errorOrSpinnerPage }
}