

import { NextRouter } from "next/router"
import { Action } from "redux-saga"

import { IHydraCollection } from "@modules/backend-definitions/src/models/hydra"
import { ILoggerAPI, IRequestStateAPI, IStorageAPI } from "@modules/frontend-definitions/src"

import { IOIDCAPI } from "./models/IOIDCAPI"
import { IOIDCProvider } from "./models/IOIDCProvider"
import { OIDCRequestScopes } from "./models/request-states"
import { OIDCStateSelector } from "./redux/reducer"

// #region localStorage keys

export enum OIDCLocalStorageKeys {
  /**
   * Local storage key name for the `authState` that is communicated with the OIDC provider.
   */
  AuthState = "auth_state",

  /**
   * Local storage key name for the identifier for the Proof Key For Code Exchange (PKCE) process
   * that is communicated with the OIDC provider.
   * @see https://oauth.net/2/pkce/
   */
  CodeVerifier = "pkce_code_verifier",

  /**
   * Local storage key name for a only-once-used key that is communicated with the OIDC provider.
   * @see https://en.wikipedia.org/wiki/Cryptographic_nonce
   */
  Nonce = "nonce"
}

// #region external configuration object

/**
 * Definition of required configuration data of the OICD module.
 * Allows to inject required services and APIs, so that the module itself has minimal outbound dependencies.
 */
export interface OIDCConfig<
  /** Return type of the host application backend's login endpoint. */
  AuthType = any,
  /** Return type of the host application backend's OIDC provider list. */
  ProviderCollection extends IHydraCollection<IOIDCProvider> = IHydraCollection<IOIDCProvider>
> {
  /**
   * URL where the OIDC provider should route the user after authenticating.
   * A page on the local frontend/client that is registered (!) with the provider.
   * Do not change without changing at provider's end simultaneously.
   */
  redirectURI: string
  /** A BasicAuth string (base64 encoded) required for access to the provider's API (esp. token endpoint) */
  basicAuth: string
  /** Definition of the interactions with the host application backend API. */
  oidcAPI: IOIDCAPI<AuthType, ProviderCollection>
  /** Collection of functions for request state handling. */
  requestStateAPI: IRequestStateAPI<OIDCRequestScopes>
  /** Collection of functions for interaction with the local storage. */
  storageAPI: IStorageAPI
  /** Collection of functions to log stuff. */
  loggerAPI: ILoggerAPI

  /** Selector function to request the `OICDState` from the host application's AppState. */
  selectOIDCState: OIDCStateSelector
  /** Function to route the user's browser to the given URL. */
  routeTo: (url: string) => void
  /** Function to retrieve a param from the current URL. */
  getStringFromQuery: (router: NextRouter, param: string) => string
  /** Function to handle errors emerging in an OICD saga. */
  handleSagaError: (sagaName: string, errorMessage: string, action: Action<any>, error: any) => void
  /** Function to get the error code for an unknown error. */
  getUnknownErrorCode: () => string
}

/**
 * Configuration of the OICD module.
 * Not exported to not be overwritten unintentionally.
 * Get its value by calling `getOIDCConfig()`.
 */
let oidcConfig: OIDCConfig

/**
 * Initializes the OICD module with the given configuration.
 */
export const initializeOIDCModule = <
  AuthType = any,
  ProviderCollection extends IHydraCollection<IOIDCProvider> = IHydraCollection<IOIDCProvider>
>(config: OIDCConfig<AuthType, ProviderCollection>): void => {
  oidcConfig = config
}

/**
 * Returns the current OICD configuration object.
 * @returns the current OICD configuration
 */
export const getOIDCConfig = (): OIDCConfig => oidcConfig

// #endregion