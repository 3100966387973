import { Action } from "redux"

import { IHydraCollection } from "@modules/backend-definitions/src/models/hydra"

import { getOIDCConfig } from "../config"
import { IOIDCProvider } from "../models/IOIDCProvider"

// #region reducer actions

/**
 * List of available reducer action types.
 */
enum OIDCReducerActionTypes {
  SetOIDCProviders = "SET_OIDC_PROVIDERS",
  SetOIDCToken = "SET_OIDC_TOKEN",
  SetPlatformAuthReply = "SET_PLATFORM_AUTH_REPLY"
}

/**
 * Abstract base interface of all OICD reducer actions.
 */
interface IOIDCReducerAction extends Action<OIDCReducerActionTypes> {
  type: OIDCReducerActionTypes
}

interface ISetOIDCProvidersAction extends IOIDCReducerAction {
  oidcProviders: IHydraCollection<IOIDCProvider>
  type: OIDCReducerActionTypes.SetOIDCProviders
}

interface ISetOIDCTokenAction extends IOIDCReducerAction {
  idToken: string
  type: OIDCReducerActionTypes.SetOIDCToken
}

interface ISetPlatformAuthReplyAction<AuthType = any> extends IOIDCReducerAction {
  platformAuthReply: AuthType
  type: OIDCReducerActionTypes.SetPlatformAuthReply
}

/**
 * Creates an action to store the loaded OIDC provider information.
 */
export const setOIDCProvidersAction = (oidcProviders: IHydraCollection<IOIDCProvider>): ISetOIDCProvidersAction => ({
  oidcProviders,
  type: OIDCReducerActionTypes.SetOIDCProviders
})

/**
 * Creates an action to store the fetched OIDC token.
 */
export const setOIDCTokenAction = (idToken: string): ISetOIDCTokenAction => ({
  idToken,
  type: OIDCReducerActionTypes.SetOIDCToken
})

/**
 * Creates an action to store the host application backend's auth reply.
 */
export const setPlatformAuthReplyAction = <AuthType = any>(platformAuthReply: AuthType): ISetPlatformAuthReplyAction => ({
  platformAuthReply,
  type: OIDCReducerActionTypes.SetPlatformAuthReply
})

// #endregion

// #region reducer

/**
 * Structure of the OIDC module's content in the host application's AppState.
 */
interface IOIDCState<AuthType = any> {
  /**
   * List of OICD providers that are supported by the host application backend.
   */
  oidcProviders: IOIDCProvider[]
  /**
   * OICD ID token, returned from the OICD provider, to be sent to the host backend
   * to perform the login.
   */
  idToken: string
  /**
   * Reply from the host application backend after performing the login with the ID token.
   */
  platformAuthReply: AuthType
}

/**
 * Type of a selector function to request the `OICDState` from the host application's AppState.
 */
export type OIDCStateSelector = (state: any) => IOIDCState

/**
 * Initial value of the OICD provider list.
 *
 * @todo oauth verify that `[]` is the best of all possible values (compared to `undefined` and others)
 * @todo oauth remove "export" if no longer used elsewhere
 */
export const OIDC_PROVIDER_DEFAULT_STATE_VALUE: IOIDCProvider[] = []

/**
 * Inital OICD module's state content in the host application's AppState.
 */
const initialOIDCState: IOIDCState = {
  oidcProviders: OIDC_PROVIDER_DEFAULT_STATE_VALUE,
  idToken: undefined,
  platformAuthReply: undefined
}

/**
 * Reducer for the OICD state.
 */
export const oidcReducer =
  (state: IOIDCState = initialOIDCState, action: IOIDCReducerAction): IOIDCState => {
    switch (action.type) {
      case OIDCReducerActionTypes.SetOIDCProviders:
        const newCollection: IOIDCProvider[] = []
        // @todo oauth: why doesn't typescript infer the correct type of action, like it does in the main app?
        const data = (action as ISetOIDCProvidersAction).oidcProviders
        data["hydra:member"].forEach((provider: IOIDCProvider) => {
          newCollection.push(provider)
        })
        getOIDCConfig().loggerAPI.debug("oidcReducer, SetOIDCProviders", newCollection)
        return {
          ...state,
          oidcProviders: newCollection
        }

      case OIDCReducerActionTypes.SetOIDCToken:
        getOIDCConfig().loggerAPI.debug("oidcReducer, SetOIDCToken", (action as ISetOIDCTokenAction).idToken)
        return {
          ...state,
          // @todo oauth: why doesn't typescript infer the correct type of action, like it does in the main app?
          idToken: (action as ISetOIDCTokenAction).idToken
        }

      case OIDCReducerActionTypes.SetPlatformAuthReply:
        getOIDCConfig().loggerAPI.debug("oidcReducer, SetPlatformAuthReply", (action as ISetPlatformAuthReplyAction).platformAuthReply)
        return {
          ...state,
          // @todo oauth: why doesn't typescript infer the correct type of action, like it does in the main app?
          platformAuthReply: (action as ISetPlatformAuthReplyAction).platformAuthReply
        }

      default:
        return state
    }
  }

// #endregion