import { combineReducers } from "redux"

import { RequestStateSelector } from "@modules/frontend-definitions/src"
import { OIDCRequestScopes } from "@modules/oidc/src"
import { scopedRequestReducer } from "@redux/helper/reducers"
import { IRequestState } from "@redux/helper/state"
import { ScopeTypes } from "@redux/reduxTypes"

import { AppState } from "."


/**
 * For every request-Operation (loading or other requests to the API) is a requestReducer necessary to have access
 * to the state of the single request - e.g. to test, if a loading is running and the loaded data may be accessed.
 *
 * Instead of creating a requestReducer for each single use case
 * (like confirm_validation, user_registration, user_management, ... / ScopeTypes.UserForManager, ...)
 * we create default request reducers/state here: up to three for each entity type including
 * one for fetching a single entity, one for fetching a collection and one for an create/update/delete
 * operation.
 * This should be sufficent also for complex cases, e.g. that need to load a single project,
 * a list of challenges and create a proposal
 *
 * @todo but it may not be enough to load two (or more) different use-cases on the same EntityType, e.g.
 * loading FeedbackInvitations FOR and FROM the processmanager on the manager-dashboard
 */
export const requestsReducer = combineReducers({
  // attachmentDefinitionOperation: scopedRequestReducer(ScopeTypes.AttachmentDefinitionOperation),

  // discussionLoading: scopedRequestReducer(ScopeTypes.DiscussionLoading),
  // discussionCollectionLoading: scopedRequestReducer(ScopeTypes.DiscussionCollectionLoading),
  // discussionOperation: scopedRequestReducer(ScopeTypes.DiscussionOperation),

  // challengeLoading: scopedRequestReducer(ScopeTypes.ChallengeLoading),
  // challengeCollectionLoading: scopedRequestReducer(ScopeTypes.ChallengeCollectionLoading),
  // challengeOperation: scopedRequestReducer(ScopeTypes.ChallengeOperation),

  // challengeConcretizationOperation: scopedRequestReducer(ScopeTypes.ChallengeConcretizationOperation),

  // feedbackInvitationLoading: scopedRequestReducer(ScopeTypes.FeedbackInvitationLoading),
  // feedbackInvitationCollectionLoading: scopedRequestReducer(ScopeTypes.FeedbackInvitationCollectionLoading),
  // feedbackInvitationOperation: scopedRequestReducer(ScopeTypes.FeedbackInvitationOperation),

  // feedbackPostLoading: scopedRequestReducer(ScopeTypes.FeedbackPostLoading),
  // feedbackPostCollectionLoading: scopedRequestReducer(ScopeTypes.FeedbackPostCollectionLoading),
  // feedbackPostOperation: scopedRequestReducer(ScopeTypes.FeedbackPostOperation),

  // processLoading: scopedRequestReducer(ScopeTypes.ProcessLoading),
  // processesLoading: scopedRequestReducer(ScopeTypes.ProcessCollectionLoading),
  // processOperation: scopedRequestReducer(ScopeTypes.ProcessOperation),

  // projectLoading: scopedRequestReducer(ScopeTypes.ProjectLoading),
  // projectsLoading: scopedRequestReducer(ScopeTypes.ProjectCollectionLoading),
  // projectOperation: scopedRequestReducer(ScopeTypes.ProjectOperation),

  // projectMembershipOperation: scopedRequestReducer(ScopeTypes.ProjectMembershipOperation),

  // proposalAttachmentOperation: scopedRequestReducer(ScopeTypes.ProposalAttachmentOperation),

  // proposalLoading: scopedRequestReducer(ScopeTypes.ProposalLoading),
  // proposalsLoading: scopedRequestReducer(ScopeTypes.ProposalManagementCollectionLoading),
  // proposalOperation: scopedRequestReducer(ScopeTypes.ProposalOperation),

  // teamUploadOperation: scopedRequestReducer(ScopeTypes.TeamUploadOperation),

  // userLoading: scopedRequestReducer(ScopeTypes.UserLoading),
  // usersLoading: scopedRequestReducer(ScopeTypes.UserCollectionLoading),
  // userOperation: scopedRequestReducer(ScopeTypes.UserOperation),

  verificationOperation: scopedRequestReducer(ScopeTypes.VerificationOperation),

  // #region specific request reducers for module OIDC
  oidcLoadProvidersRequest: scopedRequestReducer(ScopeTypes.OIDCLoadProviders),
  oidcFetchTokenRequest: scopedRequestReducer(ScopeTypes.OIDCFetchToken),
  oidcLoginWithTokenRequest: scopedRequestReducer(ScopeTypes.OIDCLoginWithToken),
  // #endregion
})

/**
 * Accessor to the embedded request states of the OIDC module
 */
export const selectOIDCRequestState: RequestStateSelector<OIDCRequestScopes> =
  (scope: OIDCRequestScopes): (state: AppState) => IRequestState =>
    (state: AppState): IRequestState => {
      switch (scope) {
        case OIDCRequestScopes.LoadOIDCProviders:
          return state.requests.oidcLoadProvidersRequest
        case OIDCRequestScopes.FetchOIDCToken:
          return state.requests.oidcFetchTokenRequest
        case OIDCRequestScopes.LoginWithOIDCToken:
          return state.requests.oidcLoginWithTokenRequest
      }
    }