import { ReactNode } from "react"

import { RequiredObjectRoleOnEntity } from "@definitions/pageAccess"
import { useCurrentUser } from "@services/hooks/useCurrentUser"
import { userHasAccess } from "@services/hooks/usePageAccessCheck"

interface IProps extends RequiredObjectRoleOnEntity {
  children: ReactNode
}

/**
 * Wrapper to control if children are shown if the user has a/the given required role
 * and not to be shown if the user does not have the right to see the children content
 * b/c of non matching UserObjectRoles.
 */
const AuthElement: React.FC<IProps> = (props: IProps) => {
  const { children } = props
  const { roles, userObjectRoles } = useCurrentUser()

  if (userHasAccess(props, userObjectRoles, roles)) {
    return children
  }

  return <></>
}

export default AuthElement
