import {
  ChallengeRole,
  EntityTypeUserObjectRole,
  entityTypeUserObjectRoleList,
  IModel,
  INumericIdentifierModel,
  IProgram,
  IRI,
  ISlugAndNumericIdentifierModel,
  ITenant,
  IUserObjectRole,
  ProgramRole,
  TenantRole,
  UserObjectRoleObjectType,
  UserObjectRoleType
} from "@api/schema"
import { RequiredObjectRole } from "@definitions/pageAccess"
import { AppState } from "@redux/reducer"
import { selectCurrentUserId } from "@redux/reducer/auth"
import { selectCollection } from "@redux/reducer/data"
import { EntityType } from "@redux/reduxTypes"

import { entityTypeFromIModelOrIRI, hasMatches, idFromIModelOrIRI, iriFromIModelOrIRI, isEmptyNullOrUndefinedObject } from "./util"

/**
 * checks, if the user's UserObjectRoles contains at least one UOR where the user has one of the roles,
 * whatever the connected object is
 *
 * @param userObjectRoles list of user-object-roles the user may have
 * @returns true, if the user has at least one of the given UOR, regardless the connected entity
 */
export const hasUOROnAnyObjectRole = (
  userObjectRoles: IUserObjectRole[],
  requiredUserObjectRoles: UserObjectRoleType | UserObjectRoleType[]
): boolean => {
  const allUserObjectRoles = userObjectRoles?.map(userObjectRole => userObjectRole["@type"])
  return allUserObjectRoles && hasMatches(allUserObjectRoles, requiredUserObjectRoles)
}


/**
 *
 * @param entity object on which the role should match
 * @param role role the user must have to the entity
 * @returns a valid RequiredObjectRole based on the given params
 */
export const createRequiredObjectRole = (entity: IModel | IRI, role: UserObjectRoleType | UserObjectRoleType[]): RequiredObjectRole[] => {
  const roleArray: RequiredObjectRole[] = []
  if (Array.isArray(role)) {
    role.forEach(r => roleArray.push({ entity, userObjectRoleType: r }))
  } else {
    roleArray.push({ entity, userObjectRoleType: role })
  }

  return roleArray
}

/**
 * Evaluates, if at least one required object role is part of the user's object roles
 *
 * @param objectRolesOfUser all object roles the user has
 * @param requiredObjectRole the required roles
 * @returns true, if all required object roles are part of the user object roles
 *
 * @see https://futureprojects.atlassian.net/browse/FCP-1477
 */
export const matchesRequiredObjectRoles = (objectRolesOfUser: IUserObjectRole[], requiredObjectRole: RequiredObjectRole | RequiredObjectRole[]): boolean => {
  // always return true, if no ObjectRoles are required
  if (isEmptyNullOrUndefinedObject(requiredObjectRole)) {
    return true
  }

  // always return true if required object roles are empty
  if (Array.isArray(requiredObjectRole) && requiredObjectRole.length === 0) {
    return true
  }

  if (isEmptyNullOrUndefinedObject(objectRolesOfUser)) {
    return false
  }

  // check for array input
  if (Array.isArray(requiredObjectRole)) {
    return !!requiredObjectRole.find(objectRole => hasRequiredRole(objectRolesOfUser, objectRole))
  }

  // check for non-array input
  return hasRequiredRole(objectRolesOfUser, requiredObjectRole)
}

/**
 * Evaluates if a single requiredObjectRole is contained in users objectRoles
 *
 * @param objectRoles all object roles the user has
 * @param requiredObjectRole the required role
 * @returns true, if the requiredObjectRole is in objectRoles
 */
const hasRequiredRole = (objectRoles: IUserObjectRole[], requiredObjectRole: RequiredObjectRole): boolean =>
  !!objectRoles.find(myRole =>
    myRole?.["@type"] === requiredObjectRole?.userObjectRoleType
    && iriFromIModelOrIRI(myRole?.object) === iriFromIModelOrIRI(requiredObjectRole?.entity)
    && (!myRole?.expiresAt || (new Date(myRole.expiresAt).getTime() > Date.now()))
  )

/**
 * array of all UserObjectRoles that are manager roles
 */
export const UOR_MANAGER_ROLES: UserObjectRoleType[] = [
  TenantRole.Accountmanager,
  TenantRole.TenantManager,
  ProgramRole.CommunityManager,
  ChallengeRole.ChallengeManager,
]

/**
 * connects one entity with one or more roles a user may have on that entity
 */
type RolesOnEntity = {
  entity: INumericIdentifierModel
  roles: IUserObjectRole[]
}

/**
 * filters a list of UserObjectRoles for manager roles and returns an array ordered by entities and
 * a list of roles the user has on those entities
 *
 * @param userObjectRoles a list of UserObjectRoles
 * @returns list of entities connected to the manager roles the user has on it
 */
export const getManagerEntitiesWithRoles = (userObjectRoles: IUserObjectRole[]): RolesOnEntity[] => {
  const managerRoles = filterRoles(userObjectRoles, UOR_MANAGER_ROLES)

  // get all unique manageable objects
  const objects = getAllUORObjects(managerRoles, true)

  // transform the manager roles into RolesOnEntity
  const rolesOnEntity = objects.map(o => ({
    entity: o,
    roles: managerRoles.filter(r => r.object["@id"] === o["@id"])
  }))

  return rolesOnEntity
}

/**
 * filters a list of UserObjectRoles for specific roles
 *
 * @param userObjectRoles a list of UserObjectRoles
 * @param roleFilter for which roles should userObjectRoles be filtered
 * @returns a filtered list
 */
export const filterRoles = <GenericIUserObjectRole extends IUserObjectRole>(userObjectRoles: IUserObjectRole[], roleFilter: UserObjectRoleType | UserObjectRoleType[]): GenericIUserObjectRole[] =>
  userObjectRoles?.filter(role => Array.isArray(roleFilter) ? roleFilter.includes(role["@type"]) : roleFilter && roleFilter === role["@type"]) as GenericIUserObjectRole[] ?? []

/**
 * count the roles a list of UserObjectRoles for specific objects
 *
 * @param userObjectRoles a list of UserObjectRoles
 * @param roleFilter
 * @param objectFilter for which objects should userObjectRoles be filtered
 * @returns a filtered list
 */
export const countRoles = (userObjectRoles: IUserObjectRole[], roleFilter: UserObjectRoleType | UserObjectRoleType[], objectFilter?: UserObjectRoleObjectType | UserObjectRoleObjectType[]): number =>
  filterRoles(userObjectRoles, roleFilter)?.filter(userObjectRole => Array.isArray(objectFilter)
    ? objectFilter?.map(object => object.id)?.includes(userObjectRole.object.id)
    : objectFilter && objectFilter.id === userObjectRole.object.id
  ).length ?? 0

/**
 * Selects the UserObjectRoles from the user with the given userId from the state
 *
 * @param state the current AppState
 * @param userId the id of the user or null/undefined if currentUser should be used
 * @returns an array of UserObjectRoles
 */
export const selectUserObjectRoles = (state: AppState, userId?: number): IUserObjectRole[] => {
  if (!userId) {
    userId = selectCurrentUserId(state)
  }

  return selectCollection<IUserObjectRole>(state, EntityType.UserObjectRole)
    .filter(objectRole => idFromIModelOrIRI(objectRole.user) === userId)
}

/**
 * @param userObjectRoles an array of UserObjectRoles
 * @param filterForUniqueObject to filter out doublettes
 * @returns an array of all objects connected to the given UserObjectRoles.
 *
 * NOTE: this function may fail if invalid Object-Role constellations are provided (e.g. in tests)
 */
export const getAllUORObjects = (userObjectRoles: IUserObjectRole[], filterForUniqueObject: boolean): INumericIdentifierModel[] => {
  let objects = userObjectRoles?.map(uor => uor.object) ?? []
  if (filterForUniqueObject) {
    objects = objects.filter((entity, indexInArray, array) =>
      // findIndex returns the index of the first occurrence of the object with the @id
      // and by comparing with the index of the filter function the "later" objects
      // with the same @id are filtered out
      array.findIndex((entityToFindIndexFor) => entity["@id"] === entityToFindIndexFor["@id"])
      === indexInArray
    )
  }

  return objects
}

/**
 *
 * @param userObjectRoles a list of UserObjectRoles to be filtered
 * @param iri IRI if the object that is connected by the searched UserObjectRole
 * @returns the first UserObjectRole of the given list, that matches the given iri
 */
export const getUserObjectRoleByObjectIRI = (userObjectRoles: IUserObjectRole[], iri: IRI): IUserObjectRole =>
  userObjectRoles.find(uor => uor.object["@id"] === iri)

/**
 * @param userObjectRoles a list of UserObjectRoles to be filtered
 * @param slugOrId slug or id of an object the user has a role in
 * @returns the UserObjectRole that nested project matches the given slug within the given userObjectRoles or null if not found
 */
export const getUORBySlugOrIdFromUserObjectRoles = (userObjectRoles: IUserObjectRole[], slugOrId: string, entityType: EntityTypeUserObjectRole): IUserObjectRole =>
  userObjectRoles.find(uor => {
    const uorObject = uor.object as ISlugAndNumericIdentifierModel
    return (uorObject.slug === slugOrId || idFromIModelOrIRI(uorObject).toString() === slugOrId) && entityTypeFromIModelOrIRI(uorObject) === entityType
  })

/**
 * Check's if the given entityType is a type of EntityTypeUserObjectRole
 * @param entityType
 * @returns true is of type EntityTypeUserObjectRole
 */
export const isEntityTypeUserObjectRole = (entityType: EntityType): entityType is EntityTypeUserObjectRole => entityTypeUserObjectRoleList.includes(entityType as EntityTypeUserObjectRole)

/** checks if a list of UORs contain a tenant role for the given tenant
 *
 * @param tenant tenant or IRI to be checked
 * @param userObjectRoles list of UORs of a user
 * @returns true, if there is a TenantManager role on the given tenant in the given userObjectRoles
 */
export const hasTenantManagerRoleOn = (tenant: ITenant | IRI, userObjectRoles: IUserObjectRole[]): boolean =>
  hasRoleOn(tenant, TenantRole.TenantManager, userObjectRoles)

/**
 * checks if a list of UORs contain a community manager role for the given program
 *
 * @param program program or IRI to be checked
 * @param userObjectRoles list of UORs of a user
 * @returns true, if there is a CommunityManager role on the given program in the given userObjectRoles
 */
export const hasCommunityManagerRoleOn = (program: IProgram | IRI, userObjectRoles: IUserObjectRole[]): boolean =>
  hasRoleOn(program, ProgramRole.CommunityManager, userObjectRoles)

/**
 * Checks if a given list of UserObjectRoles contains a given role onto a given object.
 *
 * NOTE this is not typesafe; object and role may not be compatible.
 * Therefor this method is not exported, but "proxied" via typed methods (above).
 * @todo make object+role typesafe / compatible, @see https://futureprojects.atlassian.net/browse/FCP-1654
 *
 * @param object the object where a relation is expected
 * @param role the role that is expected onto the object
 * @param userObjectRoles a list of UserObjectRoles to be searched for the object-role-relation
 * @returns true, if the role and the object are connected within the given list of UserObjectRoles; otherwise: false
 */
const hasRoleOn = (object: UserObjectRoleObjectType | IRI, role: UserObjectRoleType, userObjectRoles: IUserObjectRole[]): boolean => {
  if (!userObjectRoles || !object || !role) {
    return false
  }

  return !!userObjectRoles.find(uor =>
    uor.object["@id"] === iriFromIModelOrIRI(object) && uor["@type"] === role
  )
}
